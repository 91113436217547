import React, { Component } from 'react';
import $ from "jquery";
class Slider extends Component {
    componentDidMount() {
        let windowHeight = window.innerHeight;
            $('.main-slider').css("height",windowHeight)
            $('.main-slider .row').css("height",windowHeight)
    }

    render() {
        return (
            <div className="main-slider">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 mx-auto text-center slider-text">
                            <h4>Heyooo</h4>
                            <p>&#60; html &#62;</p>
                            <p>&#60; title &#62; </p>
                            <p>Front-End
                                Developer & Shopify Liquid Code Developer</p>
                            <p>&#60;/title &#62;</p>
                            <p>&#60; /html &#62;</p>
                            <div className="slider-btn">
                                <a className="btn" href="javascript:void(0)">Get Started</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Slider;