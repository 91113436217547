import React, { Component } from 'react';
import $ from "jquery";
import Slider from "react-slick";
import FirstSlider from './images/html5.jpg';
import SecondSlider from './images/css3.jpg';
import sixSlider from './images/js.jpeg';
import ThirdSlider from './images/jquery.jpg';
import FivethSlider from './images/react-logo-oguzhansengul.jpg';
import FourthSlider from './images/liquid-code.jpg';
import SkillBar from 'react-skillbars';

class FirstContent extends Component {
    componentDidMount() {
        $(".Headers nav li").each(function (){
            $(this).hover(function (){
                $(this).toggleClass('menu-active')
            })
        })
    }

    render() {

        const settings = {
            dots: true,
            infinite: true,
            arrows: false,
            autoplay: true,
            loop:true,
            lazyLoad: true,
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide: 1,
            autoplaySpeed: 2000,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const skills = [
            {type: "HTML5", level: 100},
            {type: "CSS3", level: 100,
                "color": {
                    "bar": "#2980b9",
                    "title": {
                        "background": "#3498db",
                        "text": "white"
                    }
                }},
            {type: "Shopify", level: 100,"color": {
                    "bar": "#81c038",
                    "title": {
                        "background": "#81c038",
                        "text": "white"
                    }
                }},
            {type: "jQuery", level: 90,"color": {
                    "bar": "#5a68a5",
                    "title": {
                        "background": "#5a68a5",
                        "text": "white"
                    }
                }},

            {type: "React", level: 70,"color": {
                    "bar": "#00d8ff",
                    "title": {
                        "background": "#00d8ff",
                        "text": "white"
                    }
                }},

        ];

        return (
            <div className="site-section" id="my-information">
                <section className="mini-slider">
                    <div className="container">
                        <div className="row">
                            <div className="text-center">
                                <h4>Technologies I specialize in.</h4>
                                <h2>The areas I specialize in are listed below.
                                </h2>
                            </div>
                            <div id="mini-slider">
                                <Slider {...settings}>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={FirstSlider} alt=""/>
                                            <h3>HTML5</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={SecondSlider} alt=""/>
                                            <h3>CSS3</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={sixSlider} alt=""/>
                                            <h3>JS</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={ThirdSlider} alt=""/>
                                            <h3>jQuery</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={FivethSlider} alt=""/>
                                            <h3>React</h3>
                                        </div>
                                    </div>
                                    <div className="mini-slider-desc text-center">
                                        <div>
                                            <img src={FourthSlider} alt=""/>
                                            <h3>Shopify (Liquid Code)</h3>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                            {/*<div className="software-skills text-center">
                                <h4>Software Skills</h4>
                                <SkillBar skills={skills}/>
                            </div>*/}
                        </div>
                    </div>

                </section>
            </div>
        );
    }
}

export default FirstContent;