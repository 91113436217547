import React, { Component } from 'react';
import $ from "jquery";
import Github from "../footer/github.png";
class Headers extends Component {
    componentDidMount() {
        $('#form-checked a').click(function(){
            console.log("testere")
            $('#submit-form input').removeClass('btn-disable')
            $('#form-checked img').animate({
                opacity:'1'
            }, 1000)
        });
        $('#submit-form input').click(function (){
            $(this).val('Wait Please');
            $(this).addClass('btn-disable');
        })
        $(window).scroll(function() {
            if ($(this).scrollTop() > 5) {
                $('header').addClass('scrolled')
            }else {
                $('header').removeClass('scrolled')
            }
        });
        let mobilMenu = window.innerHeight;
        $('.mobile-menus').css('height',mobilMenu);
        $(".mobile-close button").click(function (){
            $(".mobile-menus").hide(1000)
        })
        $(".mobile-menu button").click(function (){
            $(".mobile-menus").show(1000)
        })
        $('.mobile-menus ul li').click(function (){
            $(".mobile-menus").hide(1000)
        })
        $(".menu-home").click(function() {
            $('html, body').animate({
                scrollTop: $("body").offset().top
            }, 1000);
        });
        $(".slider-btn").click(function() {
            $('html, body').animate({
                scrollTop: $("#my-information").offset().top
            }, 1000);
        });
        $(".menu-myinformation").click(function() {
            $('html, body').animate({
                scrollTop: $("#my-information").offset().top
            }, 1000);
        });
        $(".menu-works").click(function() {
            $('html, body').animate({
                scrollTop: $("#works").offset().top
            }, 1000);
        });
        $(".menu-projects").click(function() {
            $('html, body').animate({
                scrollTop: $("#projects").offset().top
            }, 1000);
        });
        $(".menu-whoami").click(function() {
            $('html, body').animate({
                scrollTop: $("#whoami").offset().top
            }, 1000);
        });
        $(".menu-contact").click(function() {
            $('html, body').animate({
                scrollTop: $("#contact").offset().top
            }, 1000);
        });
    }

    render() {
        return (
            <div className="Headers">
                <div className="mobile-menus text-center">
                    <div className="site-logo">
                        <a href="#home" className="menu-home">
                        <p>OĞUZHAN ŞENGÜL <span>.</span></p>
                        </a>
                    </div>
                    <div className="mobile-close">
                        <button>X</button>
                    </div>
                    <ul>
                        <li><a href="#home" className="menu-home">Home</a></li>
                        <li><a href="#my-information" className="menu-myinformation">My Information</a></li>
                        <li><a href="#works" className="menu-works">Works</a></li>
                        <li><a href="#projects" className="menu-projects">Projects</a></li>
                        <li><a href="#whoami" className="menu-whoami">Who Am I</a></li>
                        <li><a href="#contact" className="menu-contact">Contact</a></li>
                    </ul>
                    <div className="mobile-menu-footer">
                        <div className="mobile-footer">
                            <p className="email"><a href="mailto:info@oguzhansengul.com">info@oguzhansengul.com</a></p>
                            <div className="social-footer d-inline-block">
                                <div className="linkedin">
                                    <a href="https://www.linkedin.com/in/o%C4%9Fuzhan-%C5%9F-b4028770/" target="_blank">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fab"
                                             data-icon="linkedin-in" className="svg-inline--fa fa-linkedin-in fa-w-14"
                                             role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor"
                                                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z">
                                            </path>
                                        </svg>
                                    </a>
                                </div>
                                <div className="medium">
                                    <a target="_blank" href="https://medium.com/@oguzhansengul.web">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="medium"
                                             className="svg-inline--fa fa-medium fa-w-14" role="img"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path fill="currentColor"
                                                  d="M0 32v448h448V32H0zm372.2 106.1l-24 23c-2.1 1.6-3.1 4.2-2.7 6.7v169.3c-.4 2.6.6 5.2 2.7 6.7l23.5 23v5.1h-118V367l24.3-23.6c2.4-2.4 2.4-3.1 2.4-6.7V199.8l-67.6 171.6h-9.1L125 199.8v115c-.7 4.8 1 9.7 4.4 13.2l31.6 38.3v5.1H71.2v-5.1l31.6-38.3c3.4-3.5 4.9-8.4 4.1-13.2v-133c.4-3.7-1-7.3-3.8-9.8L75 138.1V133h87.3l67.4 148L289 133.1h83.2v5z"></path>
                                        </svg></a>
                                </div>
                                <div className="github">
                                    <a target="_blank" href="https://github.com/rc-oguz">
                                        <img src={Github} alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <header>
                    <div className="container">
                        <div className="row">
                            <div className="web-logo col-md-6 col-lg-4 ">
                                <div className="site-logo">
                                    <a href="#home" className="menu-home">
                                    <h1>OĞUZHAN ŞENGÜL <span>.</span></h1>
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-8 desktop-menu">
                                <nav>
                                    <ul>
                                        <li><a href="#home" className="menu-home">Home</a></li>
                                        <li><a href="#my-information" className="menu-myinformation">My Information</a></li>
                                        <li><a href="#works" className="menu-works">Works</a></li>
                                        <li><a href="#projects" className="menu-projects">Projects</a></li>
                                        <li><a href="#whoami" className="menu-whoami">Who Am I</a></li>
                                        <li><a href="#contact" className="menu-contact">Contact</a></li>
                                    </ul>
                                </nav>
                                <div className="mobile-menu ">
                                    <button>
                                        <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px"><path d="M 2 5 L 2 7 L 22 7 L 22 5 L 2 5 z M 2 11 L 2 13 L 22 13 L 22 11 L 2 11 z M 2 17 L 2 19 L 22 19 L 22 17 L 2 17 z"/></svg>
                                    </button>

                                </div>
                            </div>
                        </div>

                    </div>
                </header>
            </div>
        );
    }
}

export default Headers;