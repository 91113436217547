import emailjs from 'emailjs-com';
import React from 'react';
import Modal from 'react-modal';
import $ from "jquery";
import Checked from './checked.png';
import Error from './error-gif.gif';
import Bot from './bot-check.gif';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
export default function ContactUs() {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalErOpen, setErOpen] = React.useState(false);

    function closeModal() {
        setIsOpen(false);
    }
    function closeErModal() {
        setIsOpen(false);
    }

    function sendEmail(e) {
        e.preventDefault();
        emailjs.sendForm('service_tnztevg', 'template_gurq92i', e.target, 'user_pKAu5ywaT6wmueSElwKaH')
            .then((result) => {
                setIsOpen(true);
                console.log("Message Send");
                $('.form-submit').val('Send')
                $('.form-submit').removeClass('btn-disable')
                $('#form-checked img').animate({
                    opacity:'0'
                }, 1000)
                e.target.reset()
            }, (error) => {
                setErOpen(true);
                console.log("Message Error");
                $('.form-submit').val('Send');
                $('.form-submit').removeClass('btn-disable');

            });
    }
    return (
        <div className="container" id="contact">
            <div className="row">
                <div className="site-section pt-0 pb-3">
                    <h4 className="text-center">Contact me!</h4>
                </div>
                <div className="text-center">
                    <div className="col-lg-8 col-12 d-inline-block">
                        <form className="contact-form" onSubmit={sendEmail}>
                            <div>
                                <input type="hidden" name="contact_number" />
                                <input type="text" name="user_name" placeholder="Name Surname" required/>
                            </div>
                            <div>
                                <input type="email" name="user_email" placeholder="E-mail Address" required/>
                            </div>
                            <div>
                                <textarea name="message" placeholder="Message" required/>
                            </div>
                            <div id="form-checked" className="pb-4">

                                <a><span></span><img src={Bot} alt="Bot Check"/>I'm not bot</a>
                            </div>
                            <div id="submit-form">
                                <input className="form-submit btn-disable" type="submit" value="Send" />
                            </div>
                        </form>

                    </div>

                </div>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Success Message"
            >
                <div className="text-center modal-img">
                <div className="d-inline-block">
                <img src={Checked} alt="Message Check"/>
                </div>
                <div className="text-center">
                    <h5>Message Send!</h5>
                </div>
                <div>
                <button onClick={closeModal}>X</button>
                </div>
                </div>
             </Modal>
            <Modal
                isOpen={modalErOpen}
                onRequestClose={closeErModal}
                style={customStyles}
                contentLabel="Success Message"
            >
                <div className="text-center modal-img">
                <div className="d-inline-block">
                    <img src={Error} alt="Message Check"/>
                </div>
                <div className="text-center">
                    <h5>Message could not be sent</h5>
                </div>
                <div className="text-center">
                    <button onClick={closeErModal}>X</button>
                </div>
                </div>
            </Modal>
        </div>
    );
}