import React, { Component } from 'react';
import Header from './header/Headers';
import Slider from './slider/Slider';
import FirstContent from './contect/FirstContent';
import SecondContent from './contect/SecondContent';
import ThirdContent from './contect/ThirdContent';
import FourContent from './contect/FourContent';
import Footer from './footer/Footer';
import Contact from './footer/Contact';
import './Custom.css';
import './Responsive.css';

class App extends Component {
    render() {
        return (
            <div className="mainCode">
                <Header/>
                <Slider/>
                <FirstContent/>
                <SecondContent/>
                <ThirdContent/>
                <FourContent/>
                <Contact/>
                <Footer/>
            </div>
        );
    }
}
export default App;